import { showFlashMessage } from '../utils.js';

$(document).on('turbolinks:load', function () {
  if ($('#upload-photos-modal').length) {
    $(".success-checkmark").hide();

    $('#upload-photos-modal').on('shown.bs.modal', function () {
      if ($("#ad-attachments-form").length) {
        $("#ad-attachments-form").show();
        // Set Parsley localization based on RoR I18n
        window.Parsley.setLocale($('#ad-attachments-form').attr('locale'));

        $('#ad-attachments-form').parsley({
          trigger: 'change focusout submit',
          errorClass: 'is-invalid',
          successClass: 'is-valid',
          errorsContainer(field) {
            var formGroup = field.$element.parent();
            formGroup.append("<div class='invalid-feedback d-block'></div>");

            return formGroup.children().last();
          }
        })
      }
    })

    $('form#ad-attachments-form').on('ajax:error', function (event) {
      showFlashMessage(
				"danger",
				event.detail[0],
				$("#upload-photos-modal"),
				false
			);
    });

    $('#upload-photos-modal').on('hidden.bs.modal', function () {
      $('#form-flash-messages').addClass("none");
      $(".success-checkmark").hide();
      location.reload();
    });
  }
});
