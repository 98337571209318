// *****************
// omniauth_account new form
// *****************
import 'parsleyjs/dist/parsley.min'
import 'parsleyjs/dist/i18n/en'
import 'parsleyjs/dist/i18n/es'
import { enableDependencyInput, loadIdentificationRegex, loadCountryIDForPhone} from '../utils.js';

$(document).on('turbolinks:load', function () {
  if ($("#omniauth_accounts_new_form").length) {
    // Set Parsley localization based on RoR I18n
    window.Parsley.setLocale($("#omniauth_accounts_new_form").attr("locale"));

    $("#omniauth_accounts_new_form").parsley({
      trigger: "change focusout submit",
      errorClass: "is-invalid",
      successClass: "is-valid",
      errorsContainer(field) {
        var formGroup = field.$element.parent();
        formGroup.append("<div class='invalid-feedback d-block'></div>");

        return formGroup.children().last();
      },
    });

    $("#omniauth_account_identification_format_id").change(function () {
      loadIdentificationRegex(
        $("#omniauth_account_identification_format_id"),
        $("#omniauth_account_identification"),
        "omniauth_account"
      );
      enableDependencyInput(
        $("#omniauth_account_identification_format_id"),
        $("#omniauth_account_identification")
      );
    });

    enableDependencyInput(
      $("#omniauth_account_identification_format_id"),
      $("#omniauth_account_identification")
    );

    $("#omniauth_account_city_id").change(function () {
      loadCountryIDForPhone(
        $("#omniauth_account_city_id"),
        $("#omniauth_account_mobile_number"),
        "omniauth_account"
      );
      enableDependencyInput(
        $("#omniauth_account_city_id"),
        $("#omniauth_account_mobile_number")
      );
    });
  }
});
