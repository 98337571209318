// *****************
// Account new form
// *****************
import {
  enableDependencyInput,
  loadIdentificationRegex,
  loadCountryIDForPhone,
} from "../utils.js";

function deleteNestedField(e) {
  e.preventDefault();

  let nestedFieldId = $(this)
    .closest(".nested-fields")
    .find("input[name*='id']:not([name*='country_id'])")
    .val();

  if (!nestedFieldId) {
    $(this).closest(".nested-fields").remove();
    return;
  }

  let confirmDelete = confirm(i18n.t("info.delete_confirmation"));

  if (!confirmDelete) {
    return;
  }

  // Update the _destroy input value
  $(this).closest(".nested-fields").find("input[name*='_destroy']").val("true");

  // Hide the nested fields
  $(this).closest(".nested-fields").addClass("d-none");
}

function startParsleyValidation(form) {
  // Reset the parsley validation
  form
    .parsley({
      trigger: "change focusout submit",
      errorClass: "is-invalid",
      successClass: "is-valid",
      errorsContainer: (field) => {
        let formGroup = field.$element.parent();
        formGroup.append("<div class='invalid-feedback d-block'></div>");
        return formGroup.children().last();
      },
    });
}

function destroyParsleyValidation(form) {
  form.parsley().destroy();
  form.find(".invalid-feedback").remove();
}

function handleNestedFields(
  form,
  fieldGroupClass,
  addLinkClass,
  removeLinkClass
) {
  form.on("click", addLinkClass, function (e) {
    destroyParsleyValidation(form);
    e.preventDefault();

    var fieldGroup = $(this).parent().next(fieldGroupClass);
    var newFields = fieldGroup.children(".nested-fields").first().clone();
    var formIndex = fieldGroup.children(".nested-fields").length;
    var klass = fieldGroupClass.replace(".", "").replace("s", "");

    // Update the names and ids of the cloned inputs
    newFields.html(
      newFields
        .html()
        .replace(new RegExp(`${klass}_0`, "g"), `${klass}_${formIndex}`)
    );

    // Clear the values of the cloned inputs except country_id
    newFields.find("input:not([name*='country_id'])").val("");
    newFields.find("input[name*='id']:not([name*='country_id'])").remove();

    // Show the remove link
    newFields.find(`.remove-${klass}`).removeClass("d-none");

    // Remove the is-valid and is-invalid classes
    newFields.find("input").removeClass("is-valid is-invalid");

    // Update the data-parsley-remote-options attribute
    var parsleyOptions = JSON.parse(
      JSON.stringify(
        $(newFields.find("input")[0]).data("parsley-remote-options")
      )
    );
    parsleyOptions["data"]["account"]["id"] = null;
    $(newFields.find("input")[0]).attr(
      "data-parsley-remote-options",
      JSON.stringify(parsleyOptions)
    );

    // Reset inputmask
    Inputmask().mask(
      newFields.find(
        "input[data-inputmask],input[data-inputmask-regex],input[data-inputmask-inputformat]"
      )
    );

    // Append the new fields to the form
    fieldGroup.append(newFields);

    // Reset the parsley validation
    startParsleyValidation(form);
  });

  form.on("click", removeLinkClass, deleteNestedField);
}

$(document).on("turbolinks:load", function () {
  if (!$("#accounts_new_form").length && !$("#accounts_edit_form").length) {
    return;
  }

  if ($("#accounts_new_form").length) {
    var form = $("#accounts_new_form");
  }

  if ($("#accounts_edit_form").length) {
    var form = $("#accounts_edit_form");
  }

  // Set Parsley localization based on RoR I18n
  window.Parsley.setLocale(form.attr("locale"));

  startParsleyValidation(form);

  // For some reason Parsley validations are present on page load
  form.find("input, select, textarea").removeClass("is-valid is-invalid");

  // Handle remove links for nested fields
  form
    .find(".phones")
    .find(".nested-fields")
    .each(function (index, element) {
      if (index !== 0) {
        $(element).find(".remove-phone").removeClass("d-none");
      }
    });

  form
    .find(".emails")
    .find(".nested-fields")
    .each(function (index, element) {
      if (index !== 0) {
        $(element).find(".remove-email").removeClass("d-none");
      }
    });

  // Handle branch nested fields
  handleNestedFields(form, ".phones", ".add-phone-link", ".remove-phone");
  handleNestedFields(form, ".emails", ".add-email-link", ".remove-email");

  // Below commented code were not removed because they might be used in the future
  // Refer to:
  // - app/views/accounts/new/_form.html.erb
  // - spec/features/accounts/create_spec.rb

  // $("#account_identification_format_id")
  //   .change(function () {
  //     loadIdentificationRegex(
  //       $("#account_identification_format_id"),
  //       $("#account_identification"),
  //       "account"
  //     );
  //     enableDependencyInput(
  //       $("#account_identification_format_id"),
  //       $("#account_identification")
  //     );
  //   })

  // enableDependencyInput(
  //   $("#account_identification_format_id"),
  //   $("#account_identification")
  // );

  // $("#account_city_id")
  //   .change(function () {
  //     loadCountryIDForPhone(
  //       $("#account_city_id"),
  //       $("#account_mobile_number"),
  //       "account"
  //     );
  //     enableDependencyInput(
  //       $("#account_city_id"),
  //       $("#account_mobile_number")
  //     );
  //   })

  // enableDependencyInput($("#account_city_id"), $("#account_mobile_number"));
});
