// *****************
// Delete account form
// *****************

$(document).on('turbolinks:load', function () {
  if ($("form#delete-account-form").length) {
    // Set Parsley localization based on RoR I18n
    window.Parsley.setLocale($('form#delete-account-form').attr('locale'));

    $('form#delete-account-form').parsley({
      trigger: 'change focusout submit',
      errorClass: 'is-invalid',
      successClass: 'is-valid',
      errorsContainer(field) {
        var formGroup = field.$element.parent();
        formGroup.append("<div class='invalid-feedback d-block'></div>");

        return formGroup.children().last();
      }
    });
  };
});
