import { showFlashMessage } from "../utils";

$(document).on("show.bs.modal", "#verify-account-modal", function (event) {
  var button = $(event.relatedTarget); // Button that triggered the modal

  // Update the modal's content
  var modal = $(this);
  modal
    .find("form#verification-request input#verification_request_to")
    .val(button.data("to"));
  modal
    .find("form#verification-request input#verification_request_to_friendly")
    .val(button.data("to"));
});

$(document).on("turbolinks:load", function () {
  if ($("#verify-account-modal").length === 0) {
    return;
  }

  const verifyAccountModal = $("#verify-account-modal"),
    verificationRequestRow = verifyAccountModal.find(
      "#verification-request.row"
    ),
    verificationCheckRow = verifyAccountModal.find(
      "#verification-check.row"
    ),
    successCheckmarkRow = verifyAccountModal.find("#success-checkmark.row"),
    verificationRequestForm = verificationRequestRow.find(
      "form#verification-request"
    ),
    verificationCheckForm = verificationCheckRow.find(
      "form#verification-check"
    );

  verifyAccountModal.on("hidden.bs.modal", function () {
    verificationRequestForm[0].reset();
    verificationRequestForm.parsley().reset();
    verificationCheckForm[0].reset();
    verificationCheckForm.parsley().reset();
    successCheckmarkRow.hide();
    verifyAccountModal.find("#form-flash-messages").addClass("none");
  });

  verifyAccountModal.on("show.bs.modal", function () {
    verifyAccountModal.find("#form-flash-messages").addClass("none");
    verificationRequestRow.show();
    verificationCheckRow.hide();
    successCheckmarkRow.hide();
    initializeForm(verificationRequestForm);
    initializeForm(verificationCheckForm);
  });

  verificationRequestForm.on("ajax:success", function (_event) {
    verificationRequestRow.hide();
    verificationCheckForm
      .find("input#verification_check_to")
      .val(
        verificationRequestForm.find("input#verification_request_to").val()
      );
    verificationCheckRow.show();
  });

  verificationRequestForm.on("ajax:error", function (event) {
    showFlashMessage("danger", event.detail[0].message, verifyAccountModal);
  });

  verificationCheckForm.on("ajax:success", function (event) {
    verificationCheckRow.hide();
    showFlashMessage("success", event.detail[0].message, verifyAccountModal);
    successCheckmarkRow.find("div.success-checkmark").removeClass("none");
    successCheckmarkRow.find("div.success-checkmark").show();
    successCheckmarkRow.show();

    setTimeout(() => location.reload(), 5000);
  });

  verificationCheckForm.on("ajax:error", function (event) {
    verificationCheckForm
      .find("input#verification_check_code")
      .addClass("is-invalid");
    showFlashMessage("danger", event.detail[0].message, verifyAccountModal);
  });
});

function initializeForm(form) {
  // Set Parsley localization based on RoR I18n
  window.Parsley.setLocale(form.attr("locale"));

  form.parsley({
    trigger: "change focusout submit",
    errorClass: "is-invalid",
    successClass: "is-valid",
    errorsContainer(field) {
      const formGroup = field.$element.parent();
      formGroup.append("<div class='invalid-feedback d-block'></div>");

      return formGroup.children().last();
    },
  });
}
