$(document).on("turbolinks:load", function () {
  Parsley.addValidator('emailmobile', {
    validateString: function(value) {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const phoneRegex = /^[678]\d{7}$/;
      return emailRegex.test(value) || phoneRegex.test(value);
    },
    messages: {
      en: 'Please enter a valid email or phone number',
      es: 'Por favor ingrese un correo electrónico o número de teléfono válido',
    },
  });
});
