// **************************
// DealerAttachments new form
// **************************

$(document).on("turbolinks:load", function () {
  if(!$("#upload-photos-modal").length) {
    return;
  }
  
  $("#upload-photos-modal").on("shown.bs.modal", function () {
    $("form#ad-attachments-form").dropzone({
      paramName: "images", // The name that will be used to transfer the file
      maxFilesize: 4, // MB
      maxFiles: 6,
      clickable: true,
      acceptedFiles: "image/png,image/jpg,image/jpeg,image/heif,image/heic",
      addRemoveLinks: true,
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      init: function () {
        let myDropzone = this;

        myDropzone.on("addedfile", function (file) {
          file.previewElement.addEventListener("click", function () {
            myDropzone.removeFile(file);
          });
        });

        myDropzone.on("success", function (file, server_response) {
          file.signed_id = server_response.signed_id;
        });

        myDropzone.on("removedfile", function (file) {
          $.ajax({
            url: window.location.href + "/attachments/" + file.signed_id,
            method: "DELETE",
            contentType: "application/json",
            success: function (_result) {
              myDropzone.options.maxFiles = myDropzone.options.maxFiles + 1;
            },
          });
        });

        $.getJSON(window.location.href + "/attachments", function (data) {
          $.each(data, function (i, image) {
            let file = { name: "image_" + i, signed_id: image.signed_id };
            let callback = null; // Optional callback when it's done
            let crossOrigin = null; // Added to the `img` tag for crossOrigin handling
            let resizeThumbnail = false; // Tells Dropzone whether it should resize the image first
            myDropzone.displayExistingFile(
              file,
              image.url,
              callback,
              crossOrigin,
              resizeThumbnail
            );
          });
          let fileCountOnServer = data.length; // The number of files already uploaded
          myDropzone.options.maxFiles =
            myDropzone.options.maxFiles - fileCountOnServer;
        });
      },
    });
  });
});
