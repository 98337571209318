import { removeEmptyInputsFromForms, populateChildSelect } from "../utils.js";
import { initializeCompare } from "../modules/compare";

$(document).on("turbolinks:load", function () {
  if (!$("#main-index").length > 0) {
    return;
  }

  $("#q_car_manufacturer_id_eq").change(function () {
    populateChildSelect(
      $("#q_car_manufacturer_id_eq"),
      $("#q_car_vehicle_model_id_eq"),
      "vehicle_models",
      "manufacturer_id"
    );
  });

  removeEmptyInputsFromForms($("#ads-filters-form"));

  initializeCompare();
});
