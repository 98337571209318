window.Parsley.addValidator("noDuplicateEmails", {
  validateString: function (value) {
    var emailFields = $('input[type="email"]');
    var duplicateEmails = emailFields.filter(function () {
      return $(this).val() === value;
    });

    // If more than one field has this value, it's a duplicate
    if (duplicateEmails.length > 1) {
      return $.Deferred().reject();
    }

    return true;
  },
  messages: {
    en: "This email is already in use",
    es: "Este correo electrónico ya está en uso"
  },
});

window.Parsley.addValidator("noDuplicatePhones", {
  validateString: function (value) {
    var phoneFields = $('input[type="tel"]');
    var duplicatePhones = phoneFields.filter(function () {
      return $(this).val() === value;
    });

    // If more than one field has this value, it's a duplicate
    if (duplicatePhones.length > 1) {
      return $.Deferred().reject();
    }

    return true;
  },
  messages: {
    en: "This phone is already in use",
    es: "Este teléfono ya está en uso"
  }
});
