import { i18n } from "../initializers/i18n";

$(document).on("turbolinks:load", function () {
  if (!$("section.user-profile").length) {
    return;
  }

  if ($("#new-photo-form").length) {
    $("#account_attachment_photo").change(function () {
      if (this.files.length > 0) {
        $("#new-photo-form").submit();
      }
    });
  }

  initializeDeleteSavedSearchBtns();
});

function initializeDeleteSavedSearchBtns() {
  $(".delete-saved-search-btn").click(function (e) {
    e.preventDefault();

    let confirmation = confirm(
      i18n.t("questions.saved_search.delete_confirmation")
    );
    if (confirmation) {
      let savedSearchId = $(this).data("id"),
        headers = {
          "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
        };

      $.ajax({
        url: "/api/v1/saved_searches/" + savedSearchId,
        method: "DELETE",
        headers: headers,
        success: function () {
          $("tr#saved-search-" + savedSearchId).remove();
        },
      });
    }
  });
}
