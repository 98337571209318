$(document).on("turbolinks:load", function () {
  return; // This was the implementation for Recaptcha V2, which is no longer used, keep it here for reference.

  const envTag = document.querySelector('meta[name="env"]');

  if (!$(".g-recaptcha").length > 0 || envTag && envTag.content === "test") {
    return;
  }

  $(".g-recaptcha").each(function () {
    var grecaptcha = $(this)
    var form = grecaptcha.closest("form");

    form.on("submit", function (event) {
      // Disable the submit button to prevent repeated clicks
      form.find('[type="submit"]').prop("disabled", true);

      // Check if reCAPTCHA has been solved
      var recaptchaResponse = grecaptcha.find('[name="g-recaptcha-response"]').val();

      if (!recaptchaResponse) {
        // Prevent the form from submitting
        event.preventDefault();

        // Add a class to the reCAPTCHA div to trigger the shake effect
        grecaptcha.addClass("shake");

        // Wait for the shake animation to finish
        setTimeout(() => grecaptcha.removeClass("shake"), 3000);

        // Enable the submit button again
        form.find('[type="submit"]').prop("disabled", false);
      }
    });
  });
});
