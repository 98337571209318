$(document).on('turbolinks:load', function () {
  if(!$('#import-ads-modal').length > 0) {
    return;
  }
  
  var modal = $('#import-ads-modal');

  modal.on('shown.bs.modal', function () {
    setTimeout(function() {
      modal.find('#import-ads-searching').addClass('none');
      modal.find('#import-ads-info, #import-ads-form').removeClass('none');
    }, 5000);
  });
});
