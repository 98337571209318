// *****************
// Session new form
// *****************
import 'parsleyjs/dist/parsley.min'
import 'parsleyjs/dist/i18n/en'
import 'parsleyjs/dist/i18n/es'

$(document).on('turbolinks:load', function () {
  if ($("#account_confirmations_new_form").length) {
    // Set Parsley localization based on RoR I18n
    window.Parsley.setLocale($('#account_confirmations_new_form').attr('locale'));

    $('#account_confirmations_new_form').parsley({
      trigger: 'change focusout submit',
      errorClass: 'is-invalid',
      successClass: 'is-valid',
      errorsContainer(field) {
        var formGroup = field.$element.parent();
        formGroup.append("<div class='invalid-feedback d-block'></div>");

        return formGroup.children().last();
      }
    });

    $('#account_confirmation_code').inputmask();
  };
});
