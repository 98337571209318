// **************************
// DealerAttachments new form
// **************************

Dropzone.options.dealerAttachmentsNewForm = {
  paramName: "logo", // The name that will be used to transfer the file
  maxFilesize: 4, // MB
  maxFiles: 1,
  acceptedFiles: "image/png,image/jpg,image/jpeg,image/heif,image/heic",
  addRemoveLinks: true,
  headers: {
    "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
  },
};
