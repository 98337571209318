import { removeEmptyInputsFromForms, populateChildSelect } from "../utils";
import { initializeCompare } from "../modules/compare";

$(document).on("turbolinks:load", function () {
  if (!$("#ads-index").length > 0) {
    return;
  }

  const form = $("#ads-filters-form");

  removeEmptyInputsFromForms(form);
  initializeCompare();
  initializeSaveSearch(form);
  refreshSaveSearchLinks(form);
  initializeFilters(form);
});

function initializeSaveSearch(form) {
  let saveSearchLink = form.find("a#save-search"),
    deleteSearchLink = form.find("a#delete-search"),
    url = "/api/v1/saved_searches",
    headers = {
      "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
    },
    saved_search_id_field = form.find("input#q_saved_search_id"),
    saved_search_id = saved_search_id_field.val();

  if (saved_search_id) {
    saveSearchLink.addClass("none");
    deleteSearchLink.removeClass("none");
  } else {
    saveSearchLink.removeClass("none");
    deleteSearchLink.addClass("none");
  }

  saveSearchLink.on("click", function (event) {
    event.preventDefault();

    form.find(':input[value=""]').attr("disabled", "disabled");

    let formArray = form.serializeArray();
    let searchParams = {};
    $.each(formArray, function (i, field) {
      if (field.value != "") {
        searchParams[field.name] = field.value.replace(/[₡$,]/g, "");
      }
    });

    let data = { saved_search: { search_params: searchParams } };

    form.find(":input").removeAttr("disabled");

    $.ajax({
      url: url,
      type: "POST",
      data: JSON.stringify(data), // send data as JSON string
      headers: headers,
      contentType: "application/json", // specify content type as JSON
      success: function (result) {
        saved_search_id_field.val(result.id);
        saveSearchLink.addClass("none");
        deleteSearchLink.removeClass("none");
      },
    });
  });

  deleteSearchLink.on("click", function (event) {
    event.preventDefault();
    let saved_search_id = form.find("input#q_saved_search_id").val();

    $.ajax({
      url: url + "/" + saved_search_id,
      type: "DELETE",
      headers: headers,
      success: function (_result) {
        saveSearchLink.removeClass("none");
        deleteSearchLink.addClass("none");
      },
    });
  });
}

function refreshSaveSearchLinks(form) {
  let saveSearchLink = form.find("a#save-search"),
    deleteSearchLink = form.find("a#delete-search"),
    saved_search_id_field = form.find("input#q_saved_search_id");

  form.on("change", function () {
    saved_search_id_field.val("");
    saveSearchLink.removeClass("none");
    deleteSearchLink.addClass("none");
  });
}

function initializeFilters(form) {
  form.find("#q_car_manufacturer_id_eq").on("change", function () {
    populateChildSelect(
      $("#q_car_manufacturer_id_eq"),
      $("#q_car_vehicle_model_id_eq"),
      "vehicle_models",
      "manufacturer_id"
    );
  });
}
