import { i18n } from "../initializers/i18n";
import imagePlaceholder from "./../../images/200x200.jpg";

export function initializeCompare() {
  refreshCompareBtns();
  initializeCompareBtns();
  initializeCompareFloatingBtn();
  initializeCompareModal();
}

// Get CSRF token from meta tag
function getCsrfToken() {
  return $('meta[name="csrf-token"]').attr("content");
}

// Create a delete button for the table header
function createDeleteButton(ad, index) {
  var deleteBtn = $(
    '<span class="btn btn-sm"><i class="fas fa-times"></i></span>'
  );
  deleteBtn.on("click", function () {
    deleteAdColumn(ad, index);
  });
  var buttonWrapper = $('<div class="delete-button-wrapper"></div>');
  buttonWrapper.append(deleteBtn);
  return buttonWrapper;
}

// Delete a column from the comparison table
function deleteAdColumn(ad, index) {
  $.ajax({
    url: "/api/v1/ads_compare/" + ad.slug,
    type: "DELETE",
    headers: {
      "X-CSRF-Token": getCsrfToken(),
    },
    success: function () {
      refreshCompareBtns();
      var table = $("table#compare-table")[0];
      for (var i = 0, row; (row = table.rows[i]); i++) {
        row.deleteCell(index + 1);
      }
    },
  });
}

// Initialize the comparison modal
export function initializeCompareModal() {
  var modal = $("#compare-modal");
  var modalBody = modal.find(".modal-body");

  modal.on("show.bs.modal", function () {
    modalBody.empty();

    $.ajax({
      url: "/api/v1/ads_compare/",
      type: "GET",
      headers: {
        "X-CSRF-Token": getCsrfToken(),
      },
      success: function (response) {
        if (response.length > 0) {
          var table = $(
            '<table id="compare-table" class="table table-bordered table-striped"></table>'
          );
          var thead = $("<thead></thead>");
          var tbody = $("<tbody></tbody>");
          var theadRow = $("<tr></tr>");

          // Create the table header
          theadRow.append('<th scope="col" class="fs-14"></th>');
          response.forEach(function (ad, index) {
            var th = $('<th scope="col" class="fs-14">' + ad.title + "</th>");
            th.append(createDeleteButton(ad, index));
            theadRow.append(th);
          });
          thead.append(theadRow);
          table.append(thead);

          // Create the table body
          tbody.append(
            createTableRow(
              response,
              "",
              (ad) =>
                `<img src="${ad.image_url["url"]}" class="img-fluid full-width lazyload compare-image" alt="${ad.title}" data-src="${ad.image_url["url"]}">`
            )
          );
          tbody.append(
            createTableRow(
              response,
              i18n.t("attributes.ad.price"),
              (ad) =>
                `<p><span class="text-custom-red">₡${ad.price_crc}</span> <span class="text-custom-black fs-12">($${ad.price_usd})</span></p>`
            )
          );
          tbody.append(
            createTableRow(
              response,
              i18n.t("attributes.ad.vehicle_subtype"),
              (ad) => ad.car.vehicle_subtype
            )
          );
          tbody.append(
            createTableRow(
              response,
              i18n.t("attributes.ad.engine"),
              (ad) => ad.car.formatted_engine
            )
          );
          tbody.append(
            createTableRow(
              response,
              i18n.t("attributes.ad.fuel"),
              (ad) => ad.car.fuel
            )
          );
          tbody.append(
            createTableRow(
              response,
              i18n.t("attributes.ad.transmission"),
              (ad) => ad.car.transmission
            )
          );
          tbody.append(
            createTableRow(
              response,
              i18n.t("attributes.ad.drivetrain"),
              (ad) => ad.car.drivetrain
            )
          );
          tbody.append(
            createTableRow(
              response,
              i18n.t("attributes.ad.mileage"),
              (ad) => ad.car.formatted_mileage
            )
          );
          tbody.append(
            createTableRow(
              response,
              i18n.t("attributes.ad.external_color"),
              (ad) => ad.car.external_color
            )
          );
          tbody.append(
            createTableRow(
              response,
              i18n.t("attributes.ad.internal_color"),
              (ad) => ad.car.internal_color
            )
          );
          tbody.append(
            createTableRow(
              response,
              i18n.t("attributes.ad.license_plate"),
              (ad) => ad.car.hidden_license_plate
            )
          );
          tbody.append(
            createTableRow(response, i18n.t("attributes.ad.features"), (ad) => {
              let ul = $('<ul class="compare-unstyled-list"></ul>');
              ad.car.features.forEach((feature) => {
                ul.append(`<li><i class="fas fa-check"></i> ${feature}</li>`);
              });

              return ul[0].outerHTML;
            })
          );
          tbody.append(
            createTableRow(
              response,
              i18n.t("attributes.ad.description"),
              (ad) =>
                `<p class="fs-14"><i>${
                  ad.description ? ad.description : ""
                }</i></p>`
            )
          );

          table.append(tbody);
          modalBody.append(table);
        } else {
          var message = i18n.t("info.compare_no_ads");
          modalBody.append(
            '<p id="compare-message-container" class="fs-16">' +
              message +
              "</p>"
          );
        }
      },
      error: function () {
        var signUpLink =
          '<a href="/accounts/new?locale=' +
          i18n.locale +
          '">' +
          i18n.t("actions.sign_up") +
          "</a>";
        var logInLink =
          '<a href="/sessions/new?locale=' +
          i18n.locale +
          '">' +
          i18n.t("actions.login") +
          "</a>";
        var message =
          signUpLink +
          i18n.t("info.compare_login_1") +
          logInLink +
          i18n.t("info.compare_login_2");
        modalBody.append(
          '<p id="compare-message-container" class="fs-16">' + message + "</p>"
        );
      },
    });
  });
}

export function initializeCompareFloatingBtn() {
  $("button#open-compare-modal").on("click", function (event) {
    var modal = $("#compare-modal");

    modal.modal("show");
  });
}

export function refreshCompareBtns() {
  var compareBtn = $("button#open-compare-modal"),
    url = "/api/v1/ads_compare/",
    headers = {
      "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
    };

  $.ajax({
    url: url,
    type: "GET",
    headers: headers,
    success: function (result) {
      $(".compare-btn").each(function () {
        var adSlug = $(this).data("slug"),
          checkIcon = $(this).find(".fa-check-square"),
          newCheckIcon = $('<span><i class="far fa-check-square"></i></span>');

        if (checkIcon.length > 0) {
          checkIcon.remove();
        }

        if (result.map((ad) => ad.slug).includes(adSlug)) {
          $(this).prepend(newCheckIcon);
        }
      });

      compareBtn.find(".badge").text(result.length);

      if (result.length > 1) {
        compareBtn.removeClass("d-none");
      } else {
        compareBtn.addClass("d-none");
      }
    },
  });
}

export function initializeCompareBtns() {
  $(".compare-btn").on("click", function (event) {
    event.preventDefault(); // Prevent the default action (navigation) of the anchor tag

    var checkIcon = $(this).find(".fa-check-square"),
      adSlug = $(this).data("slug"),
      url = "/api/v1/ads_compare/",
      headers = {
        "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
      },
      newCheckIcon = $('<span><i class="far fa-check-square"></i></span>');

    // Check if the checkmark icon exists within the clicked link
    if (checkIcon.length) {
      // If the checkmark icon exists, remove it and send a DELETE request
      $.ajax({
        url: url + adSlug,
        type: "DELETE",
        headers: headers,
        context: this,
        success: function (_result) {
          checkIcon.remove();

          refreshCompareBtns();
        },
      });
    } else {
      // Otherwise, create and append the checkmark icon and send a POST request
      $.ajax({
        url: url,
        type: "POST",
        data: { id: adSlug },
        headers: headers,
        context: this,
        success: function (_result) {
          $(this).prepend(newCheckIcon);

          refreshCompareBtns();
        },
        error: function (_result) {
          alert(i18n.t("info.compare_max_ads"));
        },
      });
    }
  });
}

export function createTableRow(response, headerText, adAttribute) {
  let row = $("<tr></tr>");
  row.append(`<th scope="row" class="fs-14">${headerText}</th>`);

  response.forEach(function (ad) {
    let adURL = `/ads/${ad.slug}`;
    let td = $(`<td>${adAttribute(ad)}</td>`);
    td.css("cursor", "pointer");

    td.on("click", function () {
      window.location.href = adURL;
    });

    row.append(td);
  });

  return row;
}
