import { showFlashMessage } from "../utils.js";

$(document).on("turbolinks:load", function () {
  if ($("#ad-report-modal").length === 0) {
    return;
  }

  var createReportModal = $("#ad-report-modal"),
    form = createReportModal.find("form#ad-report-new-form"),
    successCheckmark = createReportModal.find(".success-checkmark"),
    flashMessagesContainer = createReportModal.find("#form-flash-messages");

  form.on("ajax:success", function (event) {
    form.hide();
    successCheckmark.show();
    showFlashMessage(
      "success",
      event.detail[0].message,
      createReportModal,
      true
    );
  });
	form.on("ajax:error", function (event) {
    showFlashMessage(
      "danger",
      event.detail[0].message,
      createReportModal,
      false
    );
  });

  $("#ad-report-modal").on("hidden.bs.modal", function () {
    flashMessagesContainer.addClass("none");
    successCheckmark.hide();
    form[0].reset();
    form.parsley().reset();
  });

  $("#ad-report-modal").on("show.bs.modal", function () {
    form.show();
    // Set Parsley localization based on RoR I18n
    window.Parsley.setLocale(form.attr("locale"));

    form.parsley({
      trigger: "change focusout submit",
      errorClass: "is-invalid",
      successClass: "is-valid",
      errorsContainer(field) {
        var formGroup = field.$element.parent();
        formGroup.append("<div class='invalid-feedback d-block'></div>");

        return formGroup.children().last();
      },
    });
  });
});
