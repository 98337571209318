$(document).on("turbolinks:load", function () {
  if (!$("li#contact-list-business-hours-today").length > 0) {
    return;
  }

  let todaysBusinessHours = $("a#todays-business-hour"),
    chevronDowns = $("span#open-business-hours"),
    chevronUps = $("span#close-business-hours"),
    completeBusinessHours = $("li#contact-list-business-hours-complete");

  todaysBusinessHours.on("click", function () {
    let todaysBusinessHour = $(this),
      chevronDown = $(
        chevronDowns[todaysBusinessHours.index(todaysBusinessHour)]
      ),
      chevronUp = $(chevronUps[todaysBusinessHours.index(todaysBusinessHour)]),
      completeBusinessHour = $(
        completeBusinessHours[todaysBusinessHours.index(todaysBusinessHour)]
      );

    if (chevronDown.hasClass("none")) {
      closeCompleteBusinessHours(chevronDown, chevronUp, completeBusinessHour);
    } else {
      openCompleteBusinessHours(chevronDown, chevronUp, completeBusinessHour);
    }
  });

  chevronDowns.on("click", function () {
    let chevronDown = $(this),
      chevronUp = $(chevronUps[chevronDowns.index(chevronDown)]),
      completeBusinessHour = $(
        completeBusinessHours[chevronDowns.index(chevronDown)]
      );

    openCompleteBusinessHours(chevronDown, chevronUp, completeBusinessHour);
  });

  chevronUps.on("click", function () {
    let chevronUp = $(this),
      chevronDown = $(chevronDowns[chevronUps.index(chevronUp)]),
      completeBusinessHour = $(
        completeBusinessHours[chevronUps.index(chevronUp)]
      );

    closeCompleteBusinessHours(chevronDown, chevronUp, completeBusinessHour);
  });
});

function openCompleteBusinessHours(
  chevronDown,
  chevronUp,
  businessHoursComplete
) {
  chevronUp.removeClass("none");
  chevronUp.find("svg").removeClass("none");

  chevronDown.addClass("none");
  chevronDown.find("svg").addClass("none");

  businessHoursComplete.removeClass("none");

  setTimeout(function () {
    businessHoursComplete.css("max-height", "220px");
  }, 0);
}

function closeCompleteBusinessHours(
  chevronDown,
  chevronUp,
  businessHoursComplete
) {
  chevronUp.addClass("none");
  chevronUp.find("svg").addClass("none");

  chevronDown.removeClass("none");
  chevronDown.find("svg").removeClass("none");

  businessHoursComplete.css("max-height", "0");

  setTimeout(function () {
    businessHoursComplete.addClass("none");
  }, 500);
}
