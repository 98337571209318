import { i18n } from "../initializers/i18n";

class ValidationController {
  constructor(form) {
    this.form = form;
  }

  startParsleyValidation() {
    this.form.parsley({
      trigger: "change focusout submit",
      errorClass: "is-invalid",
      successClass: "is-valid",
      errorsContainer: this.buildErrorsContainer,
    });
  }

  destroyParsleyValidation() {
    this.form.parsley().destroy();
    this.form.find(".invalid-feedback").remove();
  }

  buildErrorsContainer(field) {
    let formGroup = field.$element.parent();
    formGroup.append("<div class='invalid-feedback d-block'></div>");
    return formGroup.children().last();
  }
}

class NestedFieldsController extends ValidationController {
  constructor(form, fieldGroupClass, addLinkClass, removeLinkClass) {
    super(form);
    this.fieldGroupClass = fieldGroupClass;
    this.addLinkClass = addLinkClass;
    this.removeLinkClass = removeLinkClass;
    
    this.bindClickEvents();
  }
  
  handleClickAddLink(e) {
    this.destroyParsleyValidation();
    e.preventDefault();
    var fieldGroup = $(e.currentTarget).parent().next(this.fieldGroupClass);
    var newFields = this.cloneAndClearFields(fieldGroup);
    fieldGroup.append(newFields);
    initializeEmployeeEmailInput(newFields.find("input.email"));
    this.startParsleyValidation();
  }

  handleClickRemoveLink(e) {
    e.preventDefault();
    let email = $(e.currentTarget)
      .closest(".nested-fields")
      .find("input.email")
      .val();

    if (email === "" || confirm(i18n.t("info.delete_confirmation"))) {
      let employeeEmails = $("input#dealer_employee_emails");
      employeeEmails.val(employeeEmails.val().replace(email, ""));
      $("input#dealer_employee_emails").trigger("change");
      $(e.currentTarget).closest(".nested-fields").remove();
    }
  }

  cloneAndClearFields(fieldGroup) {
    var newFields = fieldGroup.children(".nested-fields").first().clone();
    newFields.find("input").val("").removeClass("is-invalid");
    newFields.find(`.remove-${this.fieldGroupClass.replace(".", "").replace("s", "")}`).removeClass("d-none");
    newFields.find("input.email").attr("id", `dealer_employee_${new Date().getTime()}`);
    return newFields;
  }

  bindClickEvents() {
    this.form.on("click", this.addLinkClass, this.handleClickAddLink.bind(this));
    this.form.on("click", this.removeLinkClass, this.handleClickRemoveLink.bind(this));
  }
}

$(document).on("turbolinks:load", () => {
  if (!$("form#dealer-employees-form").length > 0) return;

  let form = $("form#dealer-employees-form");
  let validationController = new ValidationController(form);
  validationController.startParsleyValidation();

  form.find(".employees").find(".nested-fields").each(function (index, element) {
    if (index !== 0) $(element).find(".remove-employee").removeClass("d-none");
  });

  new NestedFieldsController(
    form,
    ".employees",
    ".add-employee-link",
    ".remove-employee"
  );

  $("input#dealer_employee_emails").on("change", () => form.submit());
  
  form.on("submit", function (event) {
    event.preventDefault();
    $.ajax({
      url: $(this).attr("action"),
      type: $(this).attr("method"),
      dataType: "json",
      data: $(this).serialize(),
      success: function (data) {
        let employeesTab = $("a#employees-tab");
        let newText = employeesTab
          .text()
          .replace(/\(\d+\)/, `(${data.employees.length})`);

        employeesTab.text(newText);
      },
      error: function (data) {
        // handle error
      },
    });
  });
  
  $("input.email").each(function (index, element) {
    initializeEmployeeEmailInput($(element));
  });
});

function initializeEmployeeEmailInput(element) {
  let input = $(element);

  input.on("change", function () {
    let employeeEmailsArr = [];
    let employeeEmailInputs = $("input.email");

    employeeEmailInputs.each(function (index, element) {
      $(element).parsley().validate();
      if (!$(element).hasClass("is-invalid") || $(element).val() === "") {
        employeeEmailsArr.push($(element).val());
      }
    });

    $("input#dealer_employee_emails").val(employeeEmailsArr.join(","));
    $("input#dealer_employee_emails").trigger("change");
  });
}
