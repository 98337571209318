/**
 * Populate a child select dropdown based on the selected option of a parent select dropdown.
 * This function can't be async because other functions depend on the response of this function.
 *
 * @param {Object} parent_select - jQuery object for the parent select dropdown.
 * @param {Object} child_select - jQuery object for the child select dropdown.
 * @param {String} catalog_name - Name of the catalog to use for populating the child select dropdown.
 * @param {String} search_key - Search key to use when fetching data for the child select dropdown.
 * @param {Boolean} keep_selected_value - Whether to keep the currently selected value in the child select dropdown. Default is false.
 */
export function populateChildSelect(
  parent_select,
  child_select,
  catalog_name,
  search_key,
  keep_selected_value = false
) {
  const search_value = parent_select.val();
  const isNewOption =
    search_value !== "" &&
    search_value === parent_select.find("option:selected").text();
  child_select.empty();

  if (isNewOption) return;

  $.ajax({
    url: "/catalogs",
    data: {
      catalog_name,
      search_key,
      search_value,
    },
    dataType: "json",
    async: false,
    success: function (response) {
      child_select.append(new Option("", ""));
      response.forEach(({ id, name }) => {
        const isSelected =
          keep_selected_value && id === Number(child_select.val());
        child_select.append(new Option(name, id, isSelected, isSelected));
      });
    },
    error: function (xhr, status, error) {
      console.error(`AJAX Error: ${status} - ${error}`);
    },
  });
}

export function shakeRecaptcha() {
  var recaptchaContainer = document.querySelector(".recaptcha-container");
  // Add shake class to apply the animation
  recaptchaContainer.classList.add("shake");

  // Remove the shake class after the animation duration (0.5s)
  setTimeout(function () {
    recaptchaContainer.classList.remove("shake");
  }, 1000);
}

export function scrollTo(offset) {
  // Check if the offset is defined (i.e., the element exists in the DOM)
  if (offset) {
    // Scroll the window to the top position of the priceChart element
    window.scrollTo({
      top: offset.top,
      behavior: "smooth", // Optional: smooth scrolling
    });
  }
}

export function loadIdentificationRegex(
  identificationFormatSelect,
  identificationField,
  objectName
) {
  const formatId = identificationFormatSelect.val();
  if (!formatId) {
    identificationField.attr("pattern", "");
    identificationField.attr("data-inputmask", "");
    identificationField.attr("data-parsley-remote-options", "");
    identificationField.inputmask();
    return;
  }

  $.get("/catalogs", {
    catalog_name: "identification_formats",
    search_key: "id",
    search_value: formatId,
  }).then(([data]) => {
    const { regex, input_mask, id } = data;

    identificationField.attr("pattern", regex);
    identificationField.attr("data-inputmask", `'mask': '${input_mask}'`);
    identificationField.attr("placeholder", input_mask);

    let jsonObj = JSON.parse(
      identificationField.attr("data-parsley-remote-options")
    );
    if (!jsonObj.data[objectName].metadata) {
      jsonObj.data[objectName].metadata = {};
    }
    jsonObj.data[objectName].metadata.identification_format_id = id;

    identificationField.attr(
      "data-parsley-remote-options",
      JSON.stringify(jsonObj) // Removed an extra JSON.stringify
    );

    identificationField.inputmask();
  });
}

export function loadCountryIDForPhone(citySelect, mobileField, objectName) {
  const cityId = citySelect.val();
  if (!cityId) {
    mobileField.attr("data-parsley-remote-options", "");
    return;
  }

  $.get("/catalogs", {
    catalog_name: "cities",
    search_key: "id",
    search_value: cityId,
  }).then(([data]) => {
    const remoteOptions = {
      type: "POST",
      dataType: "jsonp",
      data: {
        [objectName]: {
          object: "phone",
          attribute: "number",
          metadata: { country_id: data.country_id },
        },
      },
    };
    mobileField.attr(
      "data-parsley-remote-options",
      JSON.stringify(remoteOptions)
    );
  });
}

export function enableDependencyInput(
  parent,
  child,
  condition,
  required = false
) {
  if (parent.length === 0 || child.length === 0) return;

  let parentIsCheckbox = parent.is(":checkbox");
  let defaultCondition = parentIsCheckbox
    ? parent.is(":checked")
    : parent.val();
  let conditionToUse = condition === undefined ? defaultCondition : condition;

  addChildAttributes(conditionToUse, child, required);
}

function addChildAttributes(condition, child, required) {
  if (condition) {
    if (required) {
      child.attr("required", "required").addClass("required");
    }

    child.removeAttr("disabled").parsley().reset();
  } else {
    if (required) {
      child.removeAttr("required").removeClass("required");
    }

    child.val(null).attr("disabled", "disabled").parsley().reset();
  }
}

export function removeEmptyInputsFromForms(form) {
  // Removes empty params from form to avoid send empty parameters to controller
  form.on("submit", function () {
    $(this)
      .find("input[name], select[name], button[name]")
      .filter(function () {
        return !$(this).val();
      })
      .prop("disabled", true);
  });
}

export function showFlashMessage(event, message, modal, autoHide = false) {
  const time = 10000;

  if (typeof message === "string") {
    message = { body: message };
  }

  const { title, body } = message;
  const html = `
    <div class="alert alert-${event}">
      ${title ? `<p>${title}</p>` : ""}
      <p>${body}</p>
    </div>
  `;

  const flashMessagesContainer = modal.find("#form-flash-messages");
  flashMessagesContainer.html(html).removeClass("none");

  if (autoHide) {
    setTimeout(() => modal.modal("hide"), time);
  }
}
