import raterJs from "rater-js";
import { showFlashMessage } from "../utils.js";

$(document).on("turbolinks:load", function () {
  if ($("#create-review").length === 0) {
    return;
  }

  var createReviewModal = $("#create-review"),
    form = createReviewModal.find("#review-new-form"),
    successCheckmark = createReviewModal.find(".success-checkmark"),
    flashMessagesContainer = createReviewModal.find("#form-flash-messages");

  successCheckmark.hide();

  var reviewRater = raterJs({
    max: 5,
    starSize: 32,
    element: document.querySelector("#review_rating"),
    rateCallback: function rateCallback(rating, done) {
      this.setRating(rating);
      document.querySelector("#review_overall_rating").value = rating;
      done();
    },
  });

  createReviewModal.on("show.bs.modal", function () {
    reviewRater.clear();
    
    form.show();
    // Set Parsley localization based on RoR I18n
    window.Parsley.setLocale(form.attr("locale"));

    form.parsley({
      trigger: "change focusout submit",
      errorClass: "is-invalid",
      successClass: "is-valid",
      errorsContainer(field) {
        var formGroup = field.$element.parent();
        formGroup.append("<div class='invalid-feedback d-block'></div>");

        return formGroup.children().last();
      },
    });
  });

  form.on("ajax:success", function (event) {
    form.hide();
    successCheckmark.show();
    showFlashMessage("success", event.detail[0].message, createReviewModal, true);
  });

  form.on("ajax:error", function (event) {
    showFlashMessage("danger", event.detail[0].message, createReviewModal, false);
  });

  createReviewModal.on("hidden.bs.modal", function () {
    flashMessagesContainer.addClass("none");
    successCheckmark.hide();
    form[0].reset();
    form.parsley().reset();
  });
});
