// ************
// Dealers form
// ************

import { enableDependencyInput, loadIdentificationRegex } from "../utils";
import { destroySelect, loadSelect } from "../initializers/select";
import { i18n } from "../initializers/i18n";

function initMap() {
  var mapDivs = document.getElementsByName("map");

  if (mapDivs.length == 0) {
    return;
  }

  mapDivs.forEach(function (mapDiv) {
    initMapDiv(mapDiv);
  });
}

function initMapDiv(mapDiv) {
  if (mapDiv.dataset.mapInitialized == "true") {
    return;
  }

  var lat = parseFloat(mapDiv.dataset.lat);
  var lng = parseFloat(mapDiv.dataset.lng);
  var mapCenter = new google.maps.LatLng(lat, lng);

  var map = new google.maps.Map(mapDiv, {
    center: mapCenter,
    zoom: 12,
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: true,
    fullscreenControl: true,
  });

  mapDiv.marker = new google.maps.Marker({
    position: mapCenter,
    map: map,
  });

  google.maps.event.addListener(map, "click", function (event) {
    var latInput = $(mapDiv).parent().next().find("input[name*='latitude']");
    var lngInput = $(mapDiv).parent().next().find("input[name*='longitude']");

    latInput.val(event.latLng.lat());
    lngInput.val(event.latLng.lng());

    if (mapDiv.marker) {
      mapDiv.marker.setMap(null);
    }

    mapDiv.marker = new google.maps.Marker({
      position: event.latLng,
      map: map,
    });
  });

  mapDiv.dataset.mapInitialized = true;
}

function resetFormFields(card) {
  card
    .find("input:not([name*='country_id']):not([name*='day']), textarea")
    .val("")
    .removeClass("is-valid is-invalid");
  card.find("input[type='checkbox']").prop("checked", false);
  card.find("select").removeClass("is-valid is-invalid");
  card.find("input[name*='id']:not([name*='country_id'])").remove();
}

function createNewCard(form, formIndex) {
  let card = form.find(".branch-card").first().clone();

  // Update the id and data-target attributes so they are unique
  card.find(".card-header").attr("id", `heading${formIndex}`);
  card
    .find(".btn[data-toggle='collapse']")
    .attr("data-target", `#collapse${formIndex}`)
    .attr("aria-controls", `collapse${formIndex}`)
    // replace the header button number with the formIndex + 1
    .html(
      card
        .find(".btn[data-toggle='collapse']")
        .html()
        .replace(/1/, formIndex + 1)
    );

  card
    .find(".collapse")
    .attr("id", `collapse${formIndex}`)
    .attr("aria-labelledby", `heading${formIndex}`);

  // Replace branch_0 with branch_#{formIndex} in the html
  card.html(
    card
      .html()
      .replace(/branch_0/g, `branch_${formIndex}`)
      .replace(/branch\[0\]/g, `branch[${formIndex}]`)
      .replace(/data-map-initialized="true"/g, "data-map-initialized='false'")
  );

  // Delete emails and phones nested fields except the first one
  card.find(".emails-fields").not(":first").remove();
  card.find(".phones-fields").not(":first").remove();

  // Reset the form fields
  resetFormFields(card);

  card.find(".badge-primary").remove();
  card.find(".remove-branch").removeClass("d-none").data("id", "");

  return card;
}

function deleteBranch(e) {
  e.preventDefault();

  let branchId = $(this).data("id");

  if (!branchId) {
    $(this).closest(".branch-card").remove();
    return;
  }

  let confirmDelete = confirm(i18n.t("info.delete_confirmation"));

  if (!confirmDelete) {
    return;
  }

  // Update the _destroy input value
  $(this).closest(".branch-card").find("input[name*='_destroy']").val("true");

  // Hide the branch card
  $(this).closest(".branch-card").addClass("d-none");
}

function deleteNestedField(e) {
  e.preventDefault();

  let nestedFieldId = $(this)
    .closest(".nested-fields")
    .find("input[name*='id']:not([name*='country_id'])")
    .val();

  if (!nestedFieldId) {
    $(this).closest(".nested-fields").remove();
    return;
  }

  let confirmDelete = confirm(i18n.t("info.delete_confirmation"));

  if (!confirmDelete) {
    return;
  }

  // Update the _destroy input value
  $(this).closest(".nested-fields").find("input[name*='_destroy']").val("true");

  // Hide the nested fields
  $(this).closest(".nested-fields").addClass("d-none");
}

function handleNewBranch(form) {
  $(".add-branch-link").click((e) => {
    e.preventDefault();

    // Destroy the parsley validation and select2 fields
    destroyParsleyValidation(form);

    // Destroy the select2 fields
    destroySelect(form);

    const formIndex = $(".branch-card").length;

    let card = createNewCard(form, formIndex);

    // Collapse all the cards and expand the new one
    $(".collapse").collapse("hide");

    // Append the new card to the form
    form.find(".branches").append(card);

    // Reset the parsley validation
    startParsleyValidation(form);

    // Reset the select2 fields
    loadSelect(form);

    // Reset inputmask
    Inputmask().mask(
      $(
        "input[data-inputmask],input[data-inputmask-regex],input[data-inputmask-inputformat]"
      )
    );

    initMap();

    // Expand the new card
    $(".collapse").last().collapse("show");
  });

  form.on("click", ".remove-branch", deleteBranch);
}

function startParsleyValidation(form) {
  // Reset the parsley validation
  form
    .parsley({
      trigger: "change focusout submit",
      errorClass: "is-invalid",
      successClass: "is-valid",
      errorsContainer: (field) => {
        let formGroup = field.$element.parent();
        formGroup.append("<div class='invalid-feedback d-block'></div>");
        return formGroup.children().last();
      },
    })
    .on("field:validated", function (fieldInstance) {
      var card = fieldInstance.$element.closest(".card");

      if (card.find(".is-invalid").length > 0) {
        // if the field is invalid
        card.addClass("border-danger");
      } else {
        card.removeClass("border-danger");
      }
    });
}

function destroyParsleyValidation(form) {
  form.parsley().destroy();
  form.find(".invalid-feedback").remove();
}

function handleNestedFields(
  form,
  fieldGroupClass,
  addLinkClass,
  removeLinkClass
) {
  form.on("click", addLinkClass, function (e) {
    destroyParsleyValidation(form);
    e.preventDefault();

    var fieldGroup = $(this).parent().next(fieldGroupClass);
    var newFields = fieldGroup.children(".nested-fields").first().clone();
    var formIndex = fieldGroup.children(".nested-fields").length;
    var klass = fieldGroupClass.replace(".", "").replace("s", "");

    // Update the names and ids of the cloned inputs
    newFields.html(
      newFields
        .html()
        .replace(new RegExp(`${klass}_0`, "g"), `${klass}_${formIndex}`)
    );

    // Clear the values of the cloned inputs except country_id
    newFields.find("input:not([name*='country_id'])").val("");
    newFields.find("input[name*='id']:not([name*='country_id'])").remove();

    // Show the remove link
    newFields.find(`.remove-${klass}`).removeClass("d-none");

    // Remove the is-valid and is-invalid classes
    newFields.find("input").removeClass("is-valid is-invalid");

    // Update the data-parsley-remote-options attribute
    var parsleyOptions = JSON.parse(
      JSON.stringify(
        $(newFields.find("input")[0]).data("parsley-remote-options")
      )
    );
    parsleyOptions["data"]["dealer"]["id"] = null;
    $(newFields.find("input")[0]).attr(
      "data-parsley-remote-options",
      JSON.stringify(parsleyOptions)
    );

    // Reset inputmask
    Inputmask().mask(
      newFields.find(
        "input[data-inputmask],input[data-inputmask-regex],input[data-inputmask-inputformat]"
      )
    );

    // Append the new fields to the form
    fieldGroup.append(newFields);

    // Reset the parsley validation
    startParsleyValidation(form);
  });

  form.on("click", removeLinkClass, deleteNestedField);
}

$(document).on("turbolinks:load", function () {
  if (!$("#dealers_new_form").length && !$("#dealers_edit_form").length) {
    return;
  }

  if ($("#dealers_new_form").length) {
    var form = $("#dealers_new_form");
  }

  if ($("#dealers_edit_form").length) {
    var form = $("#dealers_edit_form");
  }

  // Set Parsley localization based on RoR I18n
  window.Parsley.setLocale(form.attr("locale"));

  startParsleyValidation(form);

  // For some reason Parsley validations are present on page load
  form.find("input, select, textarea").removeClass("is-valid is-invalid");

  $("#dealer_identification_format_id").change(function () {
    loadIdentificationRegex(
      $("#dealer_identification_format_id"),
      $("#dealer_identification"),
      "dealer"
    );
    enableDependencyInput(
      $("#dealer_identification_format_id"),
      $("#dealer_identification")
    );
  });

  enableDependencyInput(
    $("#dealer_identification_format_id"),
    $("#dealer_identification")
  );

  // Handle branches
  form
    .find(".branch-card")
    .first()
    .find(".badge-primary")
    .removeClass("d-none");
  form.find(".branch-card").first().find(".remove-branch").addClass("d-none");
  handleNewBranch(form);

  // Handle remove links for nested fields
  form
    .find(".phones")
    .find(".nested-fields")
    .each(function (index, element) {
      if (index !== 0) {
        $(element).find(".remove-phone").removeClass("d-none");
      }
    });

  form
    .find(".emails")
    .find(".nested-fields")
    .each(function (index, element) {
      if (index !== 0) {
        $(element).find(".remove-email").removeClass("d-none");
      }
    });

  // Handle branch nested fields
  handleNestedFields(form, ".phones", ".add-phone-link", ".remove-phone");
  handleNestedFields(form, ".emails", ".add-email-link", ".remove-email");
  handleNestedFields(
    form,
    ".business-hours",
    ".add-business-hours-link",
    ".remove-business-hours"
  );

  initMap();
});
