export function destroySelect(form = null) {
  var selects = form ? form.find("select") : $("select");
  selects.each(function () {
    if ($(this).data("select2")) {
      $(this).select2("destroy");
    }
  });
}

export function loadSelect(form = null) {
  form = form ? form : $("form");
  form.each(function () {
    var form = $(this);

    form.find("select").each(function () {
      initializeSelect($(this));
    });
  });
}

export function initializeSelect(element) {
  let maximumSelectionLength = null;
  if (element.hasClass("custom-select-multiple")) {
    maximumSelectionLength = element.attr("maximum_selection_length");
  }
  element.select2({
    theme: "bootstrap4",
    placeholder: element.attr("placeholder"),
    multiple: element.hasClass("custom-select-multiple"),
    minimumResultsForSearch:
      element.hasClass("custom-select-searchable") ||
      element.hasClass("custom-select-multiple")
        ? 0
        : Infinity,
    maximumSelectionLength: maximumSelectionLength,
    allowClear: JSON.parse(element.attr("allow_clear") || "false"),
    closeOnSelect: element.hasClass("custom-select-multiple") ? false : true,
    scrollAfterSelect: element.hasClass("custom-select-multiple"),
    language: $("html").attr("lang"),
    tags: JSON.parse(element.attr("tags") || "false"),
  });
}

$(document).on("select2:open", (event) => {
  const targetElement = event.target;
  if (targetElement.classList.contains("custom-select-searchable")) {
    // Set focus on the search input field
    document.querySelector("input.select2-search__field").focus();
  }
});
