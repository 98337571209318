function showRemoveFromFavorites() {
  $('a#add-to-favorites').css('display', 'none');
  $('a#remove-from-favorites').css('display', 'inline-block');
}

function showAddToFavorites() {
  $('a#add-to-favorites').css('display', 'inline-block');
  $('a#remove-from-favorites').css('display', 'none');
}

$(document).on('turbolinks:load', function () {
  $('a#add-to-favorites').on('ajax:success', showRemoveFromFavorites)

  $('a#remove-from-favorites').on('ajax:success', showAddToFavorites)
});