// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("@rails/ujs").start();
require("turbolinks").start();
require("channels");
require("jquery");
require.context("../images", true); // require (all image files)

import "@fortawesome/fontawesome-free/js/all";
import "bootstrap/dist/js/bootstrap";
import "bootstrap-slider/dist/bootstrap-slider";
import "magnific-popup/dist/jquery.magnific-popup";
import "jquery-price-format/jquery.priceformat";
import "inputmask/dist/jquery.inputmask";
// import i18n from "./initializers/i18n";
import "parsleyjs/dist/parsley.min";
import "parsleyjs/dist/i18n/en";
import "parsleyjs/dist/i18n/es";
import "dropzone/dist/min/dropzone.min";
import "lazyload/lazyload.min";
import "select2/dist/js/select2.min";
import "select2/dist/js/i18n/en";
import "select2/dist/js/i18n/es";

// window.I18n = i18n;
Dropzone.autoDiscover = false;

var jQueryBridget = require("jquery-bridget");
var Masonry = require("masonry-layout");

// make Masonry a jQuery plugin
jQueryBridget("masonry", Masonry, $);

import "./custom";
import "./accounts/form";
import "./accounts/change_password";
import "./accounts/delete_account";
import "./omniauth_accounts/form";
import "./sessions/form";
import "./reset_password_tokens/new";
import "./reset_passwords/new";
import "./ads/index";
import "./ads/form";
import "./ads/show";
import "./ads/images-form";
import "./ads/ad-report-modal";
import "./account_confirmations/form";
import "./account_confirmation_codes/new";
import "./dealers/show";
import "./accounts/show";
import "./dealers/review-form";
import "./dealers/review-delete";
import "./dealers/search-form";
import "./dealers/form";
import "./dealers/employees-form";
import "./dealers/import-ads";
import "./dealers/business-hours";
import "./open_modals";
import "./dealer_attachments/form";
import "./ad_attachments/form";
import "./main/search";
import "./modals/claim_your_account_modal";
import "./modals/verify_modal";
import "./initializers";
import "./parsley_custom_validators";
import "./recaptcha";
import "./favorite_ads/index";
import "./vehicle_statistics";

document.addEventListener("turbolinks:load", () => {
  if ($('[data-toggle="tooltip"]').length > 0) {
    $('[data-toggle="tooltip"]').tooltip();
  }

  if ($('[data-toggle="popover"]').length > 0) {
    $('[data-toggle="popover"]').popover();
  }
});

import "../stylesheets/application";
