import { destroySelect, loadSelect } from "./initializers/select";
import './initializers/parsley_custom_validators';
import { i18n } from "./initializers/i18n";

$(document).on("turbolinks:before-cache", function () {
  // this approach corrects the select2 bug when using turbolinks
  destroySelect();
});

$(document).on("turbolinks:load", function () {
  // Lazyload
  lazyload();

  // Select2
  loadSelect();

  $("a#locale-switch").on("click", function (e) {
    e.preventDefault();
    i18n.locale === "en" ? "es" : "en";
    
    window.location.href = $(this).attr("href");
  });

  // Price format
  $(".custom-price-field").each(function () {
    $(this).priceFormat({
      prefix: $(this).attr("data-symbol"),
      clearOnEmpty: true,
      centsLimit: 1,
    });
  });

  // Input mask
  Inputmask().mask(
    $("input[data-inputmask],input[data-inputmask-regex],input[data-inputmask-inputformat]")
  );
});
