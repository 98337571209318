import { showFlashMessage } from "../utils";
import { destroySelect, loadSelect } from "../initializers/select";

$(document).on("turbolinks:load", function () {
  if ($("#claim-your-account-modal").length === 0) {
    return;
  }

  const claimYourAccountModal = $("#claim-your-account-modal"),
    verificationRequestRow = claimYourAccountModal.find(
      "#verification-request.row"
    ),
    verificationCheckRow = claimYourAccountModal.find(
      "#verification-check.row"
    ),
    setPasswordRow = claimYourAccountModal.find(
      "#set-password.row"
    ),
    successCheckmarkRow = claimYourAccountModal.find(
      "#success-checkmark.row"
    ),
    verificationRequestForm = verificationRequestRow.find(
      "form#verification-request"
    ),
    verificationCheckForm = verificationCheckRow.find(
      "form#verification-check"
    ),
    setPasswordForm = setPasswordRow.find(
      "form#set-password"
    );

  claimYourAccountModal.on("hidden.bs.modal", function () {
    verificationRequestForm[0].reset();
    verificationRequestForm.parsley().reset();
    verificationCheckForm[0].reset();
    verificationCheckForm.parsley().reset();
    setPasswordForm[0].reset();
    setPasswordForm.parsley().reset();
    successCheckmarkRow.hide();
    claimYourAccountModal.find("#form-flash-messages").addClass("none");
    destroySelect(verificationRequestForm);
  });

  claimYourAccountModal.on("show.bs.modal", function () {
    claimYourAccountModal.find("#form-flash-messages").addClass("none");
    verificationRequestRow.show();
    verificationCheckRow.hide();
    successCheckmarkRow.hide();
    setPasswordRow.hide();
    loadSelect(verificationRequestForm);
    initializeForm(verificationRequestForm);
    initializeForm(verificationCheckForm);
    initializeForm(setPasswordForm);
  });

  verificationRequestForm.on("ajax:success", function (_event) {
    verificationRequestRow.hide();
    verificationCheckForm
      .find("input#verification_check_to")
      .val(verificationRequestForm.find("select#verification_request_to").val());
    verificationCheckRow.show();
  });

  verificationRequestForm.on("ajax:error", function (event) {
    showFlashMessage(
      "danger",
      event.detail[0].message,
      claimYourAccountModal
    );
  });

  verificationCheckForm.on("ajax:success", function (_event) {
    verificationCheckRow.hide();
    setPasswordForm
      .find("input#account_id")
      .val(verificationCheckForm.find("input#verification_check_code").val());
    setPasswordRow.show();
  });

  verificationCheckForm.on("ajax:error", function (event) {
    verificationCheckForm.find("input#verification_check_code").addClass("is-invalid");
    showFlashMessage(
      "danger",
      event.detail[0].message,
      claimYourAccountModal
    );
  });

  setPasswordForm.on("ajax:success", function (event) {
    setPasswordRow.hide();
    showFlashMessage(
      "success",
      event.detail[0].message,
      claimYourAccountModal
    );
    successCheckmarkRow.find("div.success-checkmark").removeClass("none");
    successCheckmarkRow.find("div.success-checkmark").show();
    successCheckmarkRow.show();

    setTimeout(() => location.reload(), 5000);
  });

  setPasswordForm.on("ajax:error", function (event) {
    showFlashMessage(
      "danger",
      event.detail[0].message,
      claimYourAccountModal
    );
  });
});

function initializeForm(form) {
  // Set Parsley localization based on RoR I18n
  window.Parsley.setLocale(form.attr("locale"));

  form.parsley({
    trigger: "change focusout submit",
    errorClass: "is-invalid",
    successClass: "is-valid",
    errorsContainer(field) {
      const formGroup = field.$element.parent();
      formGroup.append("<div class='invalid-feedback d-block'></div>");

      return formGroup.children().last();
    },
  });
}
