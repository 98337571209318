function getAnchor() {
  var currentUrl = document.URL,
    urlParts = currentUrl.split('#');

  return (urlParts.length > 1) ? urlParts[1] : null;
}

function initMap() {
  var mapDivs = document.getElementsByName("map");

  if (mapDivs.length == 0) {
    return;
  }

  mapDivs.forEach(function (mapDiv) {
    initMapDiv(mapDiv);
  });
};

function initMapDiv(mapDiv) {
  if (mapDiv.dataset.mapInitialized == "true" || mapDiv.dataset.lat == "" || mapDiv.dataset.lng == "") {
    return;
  }

  var lat = parseFloat(mapDiv.dataset.lat);
  var lng = parseFloat(mapDiv.dataset.lng);

  var map = new google.maps.Map(mapDiv, {
    center: new google.maps.LatLng(lat, lng),
    zoom: 12,
    disableDefaultUI: false,
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: true
  });

  new google.maps.Marker({
    position: new google.maps.LatLng(lat, lng),
    map: map
  });

  mapDiv.dataset.mapInitialized = true;
}

import { initializeCompare } from "../modules/compare";

$(document).on('turbolinks:load', function () {

  if (
    !($("#dealers-show").length > 0) &&
    !($("#dealer-ads-index").length > 0)
  ) {
    return;
  }

  var anchor = getAnchor();
  if (anchor != null) {
    if ($('.nav-tabs a[href="#' + anchor + '"]')[0]) {
      $('.nav-tabs a[href="#' + anchor + '"]')[0].click();
    }
  }

  if ($("#new-logo-form").length) {
    $("#dealer_attachment_logo").change(function() {
      if (this.files.length > 0) {
        $("#new-logo-form").submit();
      }
    });
  } 

  initMap();
  initializeCompare();

});
