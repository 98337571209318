import Chart from "chart.js/auto";
import { i18n } from "./initializers/i18n";
import { populateChildSelect, scrollTo, shakeRecaptcha } from "./utils.js";

function initializeForm(
  form,
  priceChart,
  countChart,
  priceChartCanvas,
  countChartCanvas
) {
  form.find("#q_manufacturer_id_eq").on("change", function () {
    populateChildSelect(
      $("#q_manufacturer_id_eq"),
      $("#q_vehicle_model_id_eq"),
      "vehicle_models",
      "manufacturer_id"
    );
  });

  form.on("submit", function (event) {
    event.preventDefault();
    validateRecaptchaAndSubmit(
      form,
      priceChart,
      countChart,
      priceChartCanvas,
      countChartCanvas
    );
  });
}

function validateRecaptchaAndSubmit(
  form,
  priceChart,
  countChart,
  priceChartCanvas,
  countChartCanvas
) {
  var showCheckboxRecaptcha = form[0].dataset.showCheckboxRecaptcha === "true";

  if (showCheckboxRecaptcha) {
    var recaptchaResponse = grecaptcha.enterprise.getResponse();

    if (recaptchaResponse.length === 0) {
      shakeRecaptcha();
    } else {
      submitForm(
        form,
        priceChart,
        countChart,
        priceChartCanvas,
        countChartCanvas
      );
    }
  } else {
    submitForm(
      form,
      priceChart,
      countChart,
      priceChartCanvas,
      countChartCanvas
    );
  }
}

function submitForm(
  form,
  priceChart,
  countChart,
  priceChartCanvas,
  countChartCanvas
) {
  // Append the token to the form data if present
  const params = new URLSearchParams(new FormData(form[0]));

  // Construct the URL with the search parameters
  const url = `${form[0].action}?${params.toString()}`;

  // Use Fetch API to submit the form via AJAX
  fetch(url, {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
    },
    credentials: "same-origin",
  })
    .then((response) => {
      // Check if the response status is 400 (Bad Request)
      if (response.status === 400) {
        return handleBadRequest(form);
      }
      // If the status is not 400, proceed to parse the JSON
      return response.json();
    })
    .then((data) => {
      updatePriceChart(priceChart, data);
      updatePriceChartLegend(data);
      updateCountChart(countChart, data);
      updateCountChartLegend(data);
      grecaptcha.enterprise.reset();
      priceChartCanvas.show();
      countChartCanvas.show();
      scrollTo($(priceChart.canvas).offset());
    })
    .catch((error) => {
      // Handle other errors as needed
      console.error(error);
    });
}

function updatePriceChartLegend(data) {
  const priceChartLegend = document.getElementById("priceChartLegend"),
    priceDirection =
      data.price_percentage_variation >= 0 ? "increased" : "decreased",
    percentage =
      data.price_percentage_variation >= 0
        ? `+${data.price_percentage_variation}`
        : data.price_percentage_variation;

  priceChartLegend.innerHTML = `
    <h2 class="text-center">${percentage}%</h2>
    <p>${i18n.t("charts.legends.price_variation_1")} <strong>${i18n.t(
    "charts.legends." + priceDirection
  )}</strong> ${i18n.t("charts.legends.price_variation_2")} <strong>${
    data.price_absolute_variation
  }</strong></p>
  `;
}

function updateCountChartLegend(data) {
  const countChartLegend = document.getElementById("countChartLegend"),
    countDirection =
      data.count_percentage_variation >= 0 ? "increased" : "decreased",
    percentage =
      data.count_percentage_variation >= 0
        ? `+${data.count_percentage_variation}`
        : data.count_percentage_variation;

  countChartLegend.innerHTML = `
    <h2 class="text-center">${percentage}%</h2>
    <p>${i18n.t("charts.legends.count_variation_1")} <strong>${i18n.t(
    "charts.legends." + countDirection
  )}</strong> ${i18n.t("charts.legends.count_variation_2")} <strong>${
    data.count_absolute_variation
  }</strong> ${i18n.t("charts.legends.count_variation_3")}</p>
  `;
}

function handleBadRequest(form) {
  // Create URLSearchParams object from the form data
  const params = new URLSearchParams(new FormData(form[0]));
  const showCheckboxRecaptcha =
    form[0].dataset.showCheckboxRecaptcha === "true";

  if (showCheckboxRecaptcha) {
    return shakeRecaptcha();
  }

  // Add show_checkbox_recaptcha param and remove g-recaptcha-response-data param
  params.append("show_checkbox_recaptcha", true);

  // Remove the g-recaptcha-response and g-recaptcha-response-data params
  params.delete("g-recaptcha-response-data");
  params.delete("g-recaptcha-response");

  // Remove empty params
  Array.from(params.keys()).forEach((key) => {
    if (params.get(key) === "") {
      params.delete(key);
    }
  });

  // Update the URL with the new params
  window.location.href = `${window.location.origin}${
    window.location.pathname
  }?${params.toString()}`;
}

function formatCurrency(value) {
  if (value === null) {
    return 0;
  }

  let formattedValue = "";

  if (i18n.locale === "en") {
    formattedValue = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value);
  } else {
    formattedValue = new Intl.NumberFormat("es-CR", {
      style: "currency",
      currency: "CRC",
    }).format(value);
  }
  return formattedValue;
}

function initializePriceChart(priceChartCanvas) {
  const priceChart = new Chart(priceChartCanvas, {
    type: "line",
    data: {
      labels: [],
      datasets: [
        {
          label: i18n.t("charts.labels.average_price"),
          data: [],
          borderColor: "#E76A6A",
          backgroundColor: "#E76A6A",
        },
        {
          label: i18n.t("charts.labels.highest_price"),
          data: [],
          borderColor: "#8B0000",
          backgroundColor: "#8B0000",
        },
        {
          label: i18n.t("charts.labels.lowest_price"),
          data: [],
          borderColor: "#FFA07A",
          backgroundColor: "#FFA07A",
        },
      ],
    },
    options: {
      plugins: {
        title: {
          display: true,
          text: i18n.t("charts.titles.price"),
        },
      },
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            callback: function (value, _index, _values) {
              return formatCurrency(value);
            },
          },
        },
      },
    },
  });

  return priceChart;
}

function updatePriceChart(priceChart, data) {
  // Update the priceChart with multiple datasets
  priceChart.data.labels = data.labels;
  priceChart.data.datasets[0].data = data.average_prices;
  priceChart.data.datasets[1].data = data.highest_prices;
  priceChart.data.datasets[2].data = data.lowest_prices;
  priceChart.update();
}

function updateCountChart(countChart, data) {
  // Update the countChart
  countChart.data.labels = data.labels;
  countChart.data.datasets[0].data = data.counts;
  countChart.update();
}

function initializeCountChart(countChartCanvas) {
  const countChart = new Chart(countChartCanvas, {
    type: "bar",
    data: {
      labels: [],
      datasets: [
        {
          label: i18n.t("charts.labels.count"),
          data: [],
          borderColor: "#E76A6A",
          backgroundColor: "#E76A6A",
        },
      ],
    },
    options: {
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: i18n.t("charts.titles.count"),
        },
      },
    },
  });

  return countChart;
}

$(document).on("turbolinks:load", function () {
  const form = $("form#car-statistics-filters");

  if (!form.length) {
    return;
  }
  const priceChartCanvasContext = document
      .getElementById("priceChart")
      .getContext("2d"),
    countChartCanvasContext = document
      .getElementById("countChart")
      .getContext("2d"),
    priceChart = initializePriceChart(priceChartCanvasContext),
    countChart = initializeCountChart(countChartCanvasContext),
    priceChartCanvas = $("#priceChart"),
    countChartCanvas = $("#countChart");

  priceChartCanvas.hide();
  countChartCanvas.hide();

  initializeForm(
    form,
    priceChart,
    countChart,
    priceChartCanvas,
    countChartCanvas
  );
});
