// *****************
// Change password new form
// *****************

function showFlashMessage(event, msg) {
  time = 10000;
  $('#form-flash-messages')[0].innerHTML = "<p class='alert alert-" + event + "'>" + msg + "</p>";
  $('#form-flash-messages').removeClass("none");

  setTimeout(function () {
    $('#form-flash-messages').addClass("none");
  }, time);
}

$(document).on('turbolinks:load', function () {
  $('form#change-password-form').on('ajax:success', function (event) {
    $('form#change-password-form')[0].reset();
    $('form#change-password-form').parsley().reset();
    showFlashMessage('success', event.detail[0].message);
  });

  $('form#change-password-form').on('ajax:error', function (event) {
    showFlashMessage('danger', event.detail[0].message);
  });

  if ($("#change-password-form").length) {
    // Set Parsley localization based on RoR I18n
    window.Parsley.setLocale($('#change-password-form').attr('locale'));

    $('#change-password-form').parsley({
      trigger: 'change focusout submit',
      errorClass: 'is-invalid',
      successClass: 'is-valid',
      errorsContainer(field) {
        var formGroup = field.$element.parent();
        formGroup.append("<div class='invalid-feedback d-block'></div>");

        return formGroup.children().last();
      }
    });
  };
});