// ************
// Ad new form
// ************
import {
  populateChildSelect,
  enableDependencyInput,
  showFlashMessage,
} from "../utils.js";

function vehicleModelChange(form) {
  populateChildSelect(
    form.find("#ad_car_attributes_vehicle_model_id"),
    form.find("#ad_car_attributes_vehicle_version_id"),
    "vehicle_versions",
    "vehicle_model_id",
    true
  );
  enableDependencyInput(
    form.find("#ad_car_attributes_vehicle_model_id"),
    form.find("#ad_car_attributes_vehicle_version_id")
  );
}

function manufacturerChange(form) {
  populateChildSelect(
    form.find("#ad_car_attributes_manufacturer_id"),
    form.find("#ad_car_attributes_vehicle_model_id"),
    "vehicle_models",
    "manufacturer_id",
    true
  );
  enableDependencyInput(
    form.find("#ad_car_attributes_manufacturer_id"),
    form.find("#ad_car_attributes_vehicle_model_id")
  );
  vehicleModelChange(form);
}

function mileageUnitChange(form) {
  let mileageElement = form.find("#ad_car_attributes_mileage_unit_id");
  let isMileageUnitId = mileageElement.val() == 1;
  let toShow = isMileageUnitId
    ? "#ad_car_attributes_mileage_km"
    : "#ad_car_attributes_mileage_mi";
  let toHide = isMileageUnitId
    ? "#ad_car_attributes_mileage_mi"
    : "#ad_car_attributes_mileage_km";

  form
    .find(toShow)
    .attr("required", "required")
    .addClass("required")
    .parsley()
    .reset();
  form.find(toShow).parent().show();

  form
    .find(toHide)
    .removeAttr("required")
    .removeClass("required")
    .parsley()
    .reset();
  form.find(toHide).parent().hide();
}

function fillForm(form, response) {
  const adAttributes = response?.ad_attributes;
  const carAttributes = adAttributes?.car_attributes;
  const carCheckItems = ["transfer_included", "enrolled"];
  const carSelectItems = [
    "manufacturer_id",
    "vehicle_model_id",
    "vehicle_version_id",
    "engine_unit_id",
    "mileage_unit_id",
    "fuel_id",
    "transmission_id",
    "drivetrain_id",
    "external_color_id",
    "internal_color_id",
    "vehicle_condition_id",
    "doors",
  ];
  const carFillAttributes = ["year", "engine", "mileage_km", "mileage_mi"];
  const adFillItems = ["description", "price_usd", "price_crc"];
  const AdSelectAttributes = ["city_id", "currency"];

  if (adAttributes) {
    for (const adSelectItem of AdSelectAttributes) {
      let itemValue = adAttributes[adSelectItem];

      if (itemValue !== null && itemValue !== undefined) {
        form.find(`#ad_${adSelectItem}`).val(itemValue).trigger("change");
      }
    }

    for (const adFillItem of adFillItems) {
      let itemValue = adAttributes[adFillItem];

      if (itemValue !== null && itemValue !== undefined) {
        itemValue = ["price_crc", "price_usd"].includes(adFillItem)
          ? itemValue + "0"
          : itemValue;

        form
          .find(`#ad_${adFillItem}`)
          .val(itemValue)
          .trigger("focusin")
          .trigger("focusout")
          .trigger("change");
      }
    }
  }

  if (carAttributes) {
    for (const carSelectItem of carSelectItems) {
      let itemValue = carAttributes[carSelectItem];

      if (itemValue !== null && itemValue !== undefined) {
        form
          .find(`#ad_car_attributes_${carSelectItem}`)
          .val(itemValue)
          .trigger("change");
      }
    }

    for (const carFillItem of carFillAttributes) {
      let itemValue = carAttributes[carFillItem];

      if (itemValue !== null && itemValue !== undefined) {
        form
          .find(`#ad_car_attributes_${carFillItem}`)
          .val(itemValue)
          .trigger("change");
      }
    }

    for (const carCheckItem of carCheckItems) {
      let itemValue = carAttributes[carCheckItem];

      if (itemValue !== null && itemValue !== undefined) {
        form
          .find(`#ad_car_attributes_${carCheckItem}`)
          .prop("checked", itemValue)
          .trigger("change");
      }
    }

    if (
      carAttributes.feature_ids !== null &&
      carAttributes.feature_ids !== undefined
    ) {
      carAttributes.feature_ids.forEach((id) => {
        form
          .find(`#ad_car_attributes_feature_ids_${id}`)
          .prop("checked", true)
          .trigger("change");
      });
    }
  }
}

function initializeRetrieveExternalDataForm(carForm) {
  if (!$("form#retrieve-external-site").length) {
    return;
  }
  var form = $("form#retrieve-external-site");

  // Set Parsley localization based on RoR I18n
  window.Parsley.setLocale(form.attr("locale"));

  form.parsley({
    trigger: "change submit",
    errorClass: "is-invalid",
    successClass: "is-valid",
    errorsContainer(field) {
      var formGroup = field.$element.parent();
      formGroup.append("<div class='invalid-feedback d-block'></div>");

      return formGroup.children().last();
    },
  });

  form.on("ajax:success", (event) => {
    var licenseTypeId = carForm.find(
      "select#ad_car_attributes_license_type_id"
    );
    var licenseTypeIdValue = licenseTypeId.val();
    var licensePlate = carForm.find("input#ad_car_attributes_license_plate");
    var licensePlateValue = licensePlate.val();
    carForm[0].reset();
    carForm.parsley().reset();

    licenseTypeId.val(licenseTypeIdValue).trigger("change");
    licensePlate.val(licensePlateValue).trigger("change");

    fillForm(carForm, event.detail[0]);

    $("#retrieve-external-data-modal").modal("hide");
  });

  form.on("ajax:error", (event) => {
    showFlashMessage(
      "danger",
      event.detail[0],
      $("#retrieve-external-data-modal"),
      false
    );
  });

  $("#retrieve-external-data-modal").on("hidden.bs.modal", function () {
    $("#form-flash-messages").addClass("none");
    form[0].reset();
    form.parsley().reset();
  });
}

function back1Actions(form) {
  form.find("select#ad_car_attributes_license_type_id").attr("required", true);
  form.find("select#ad_car_attributes_license_type_id").val("");
  form.find("input#ad_car_attributes_license_plate").attr("required", true);
  form.find("input#ad_car_attributes_license_plate").val("");
  form.find("input#ad_car_attributes_enrolled").val("true");
  form
    .find(".section#section-3")
    .find("a.back[data-section='2'][data-form='photos-container']")
    .show();
  form
    .find(".section#section-3")
    .find("a.back[data-section='1'][data-form='new-ad']")
    .hide();
}

function initializeBackButtons(form) {
  form.find("a.back").each(function () {
    var link = $(this);

    link.click(function () {
      if (
        link.attr("data-form") === "new-ad" &&
        link.attr("data-section") === "1"
      ) {
        back1Actions(form);
      } else if (
        form.attr("id") === "new-ad" &&
        link.attr("data-form") === "new-ad" &&
        link.attr("data-section") === "3"
      ) {
        next3CarActions(form);
      }

      showSection(link.attr("data-form"), link.attr("data-section"));
    });
  });
}

function next1CarActions(form) {
  var photosForm = $("form#photos");
  photosForm.hide();

  $.ajax({
    url: "/temp_ads",
    type: "POST",
    dataType: "json",
    data: form
      .serializeArray()
      .filter(
        (item) =>
          item.name === "ad[car_attributes][license_type_id]" ||
          item.name === "ad[car_attributes][license_plate]"
      )
      .reduce((obj, item) => {
        item.name = item.name.replace("ad[car_attributes]", "temp_ad");
        obj[item.name] = item.value;
        return obj;
      }, {}),
    success: function (response) {
      photosForm.attr("action", response.photos_form_action);
      initializeDropzone();
      photosForm.show();
    },
  });
}

function next3PhotosActions(form) {
  const tempAdId = form
    .serializeArray()
    .filter(
      (item) =>
        item.name === "ad[car_attributes][license_type_id]" ||
        item.name === "ad[car_attributes][license_plate]"
    )
    .reduce((obj, item) => {
      obj[item.name] = item.value;
      return obj;
    }, {});

  const url = "/temp_ads/" + Object.values(tempAdId).join(":");

  $.get({
    url: url,
    dataType: "json",
  })
    .done(function (response) {
      fillForm(form, response.data);
    })
    .fail(function (jqXHR, textStatus, errorThrown) {
      console.error(
        `Failed to fetch data from ${url}: ${textStatus} ${errorThrown}`
      );
    });

  form
    .find(".section#section-3")
    .find("a.back[data-section='2'][data-form='photos-container']")
    .show();
  form
    .find(".section#section-3")
    .find("a.back[data-section='1'][data-form='new-ad']")
    .hide();
}

function next3CarActions(form) {
  form.find("select#ad_car_attributes_license_type_id").removeAttr("required");
  form.find("select#ad_car_attributes_license_type_id").val("1");
  form.find("input#ad_car_attributes_license_plate").removeAttr("required");
  form.find("input#ad_car_attributes_license_plate").val("");
  form.find("input#ad_car_attributes_enrolled").val("false");
  form
    .find(".section#section-3")
    .find("a.back[data-section='2'][data-form='photos-container']")
    .hide();
  form
    .find(".section#section-3")
    .find("a.back[data-section='1'][data-form='new-ad']")
    .show();
}

function initializeNextButtons(form) {
  form.find("a.next").each(function () {
    const link = $(this);
    link.click(function () {
      var valid = true;
      var section = link.closest("div.section");

      if (form.attr("id") !== "photos-container") {
        section.find("select, input").each(function () {
          const input = $(this);
          input.parsley().validate();
          if (input.hasClass("is-invalid")) {
            input.focus();

            valid = false;
          }
        });
      }

      if (!valid) {
        return;
      }

      showSection(link.attr("data-form"), link.attr("data-section"));

      if (
        link.attr("data-form") === "photos-container" &&
        link.attr("data-section") === "2"
      ) {
        next1CarActions(form);
      } else if (
        form.attr("id") === "photos-container" &&
        link.attr("data-form") === "new-ad" &&
        link.attr("data-section") === "3"
      ) {
        next3PhotosActions($("form#new-ad"));
      }
    });
  });
}

function initializeForm(form) {
  initializeParsley(form);
  initializeManufacturerModelVersionSelects(form);
  initializeLicenseTypeSelect(form);
  initializeMileageUnitSelect(form);
  initializeCurrencySelect(form);

  form.submit(function () {
    form.find("#ad_price_crc").unmask();
    form.find("#ad_price_usd").unmask();
  });

  form.on("ajax:success", (event) => {
    var data = event.detail[0];
    const photosContainer = $("div#photos-container");
    const photosForm = photosContainer.find("form#photos");
    const newAdForm = $("form#new-ad");

    if (data.images_size > 0) {
      newAdForm.hide();
      window.location.href = data.ad_url;
    } else {
      photosForm.attr("action", data.photos_form_action);
      photosContainer.find("a.back").remove();
      photosContainer.find("a.next").remove();
      photosContainer.find("a.finish").attr("href", data.ad_url);
      photosContainer.find("a.finish").click(function () {
        window.location.href = data.ad_url;
      });
      showSection("photos-container", "2");
      initializeDropzone();
    }
  });

  form.on("ajax:error", (event) => {});
}

function showSection(formID, sectionID) {
  const newAdForm = $("form#new-ad");
  const photosContainer = $("div#photos-container");

  [newAdForm, photosContainer].forEach((form) => {
    form.hide();

    if (form.attr("id") === formID) {
      form.show();
      form.find("div.section").each(function () {
        $(this).hide();
      });
      form.find("div.section#section-" + sectionID).show();
    }
  });
}

function changePhotosFormNavigationLinks(filesCount, photosContainer) {
  if (filesCount === 0) {
    if (photosContainer.find("a.next").length > 0) {
      photosContainer.find("a.next").hide();
    } else if (photosContainer.find("a.finish").length > 0) {
      photosContainer.find("a.finish").hide();
    }
  } else {
    if (photosContainer.find("a.next").length > 0) {
      photosContainer.find("a.next").show();
    } else if (photosContainer.find("a.finish").length > 0) {
      photosContainer.find("a.finish").removeClass("d-none");
      photosContainer.find("a.finish").show();
    }
  }
}

function initializeDropzone() {
  const photosContainer = $("div#photos-container");
  const photosForm = $("form#photos");

  if (Dropzone.instances.length > 0) {
    Dropzone.instances.forEach((e) => {
      e.off();
      e.destroy();
    });

    photosForm.find("div.dz-preview").remove();
  }

  photosForm.dropzone({
    paramName: "images", // The name that will be used to transfer the file
    maxFilesize: 4, // MB
    maxFiles: 6,
    clickable: true,
    acceptedFiles: "image/png,image/jpg,image/jpeg,image/heif,image/heic",
    addRemoveLinks: true,
    headers: {
      "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
    },
    init: function () {
      let myDropzone = this;

      changePhotosFormNavigationLinks(
        myDropzone.getAcceptedFiles().length,
        photosContainer
      );

      myDropzone.on("addedfile", function (file) {
        file.previewElement.addEventListener("click", function () {
          myDropzone.removeFile(file);
        });
        changePhotosFormNavigationLinks(
          myDropzone.getAddedFiles().length,
          photosContainer
        );
      });

      myDropzone.on("success", function (file, server_response) {
        file.signed_id = server_response.signed_id;
      });

      myDropzone.on("removedfile", function (file) {
        $.ajax({
          url: photosForm.attr("action") + "/" + file.signed_id,
          method: "DELETE",
          contentType: "application/json",
          success: function (_result) {
            var acceptedFiles = myDropzone.getAcceptedFiles();
            var addedFiles = myDropzone.getAddedFiles();
            let currentFilesCount = acceptedFiles.length || addedFiles.length;

            changePhotosFormNavigationLinks(currentFilesCount, photosContainer);
          },
        });
      });

      if (!photosForm.attr("action").endsWith("/temp_ads/0/attachments")) {
        $.getJSON(photosForm.attr("action"), function (data) {
          $.each(data, function (i, image) {
            let file = { name: "image_" + i, signed_id: image.signed_id };
            let callback = null; // Optional callback when it's done
            let crossOrigin = null; // Added to the `img` tag for crossOrigin handling
            let resizeThumbnail = false; // Tells Dropzone whether it should resize the image first
            myDropzone.displayExistingFile(
              file,
              image.url,
              callback,
              crossOrigin,
              resizeThumbnail
            );
          });
          let fileCountOnServer = data.length; // The number of files already uploaded
          myDropzone.options.maxFiles =
            myDropzone.options.maxFiles - fileCountOnServer;

          changePhotosFormNavigationLinks(fileCountOnServer, photosContainer);
        });
      }
    },
  });
}

function initializeParsley(form) {
  // Set Parsley localization based on RoR I18n
  window.Parsley.setLocale(form.attr("locale"));

  form.parsley({
    trigger: "change submit",
    errorClass: "is-invalid",
    successClass: "is-valid",
    errorsContainer(field) {
      var formGroup = field.$element.parent();
      formGroup.append("<div class='invalid-feedback d-block'></div>");

      return formGroup.children().last();
    },
  });
}

function initializeManufacturerModelVersionSelects(form) {
  const manufacturerId = form.find("#ad_car_attributes_manufacturer_id");
  const vehicleModelId = form.find("#ad_car_attributes_vehicle_model_id");
  const vehicleVersionId = form.find("#ad_car_attributes_vehicle_version_id");

  var vehicleModelValue = vehicleModelId.val();
  var vehicleVersionValue = vehicleVersionId.val();

  manufacturerId.change(function () {
    manufacturerChange(form);
  });
  manufacturerId.trigger("change");
  manufacturerId.parsley().reset();

  vehicleModelId.change(function () {
    vehicleModelChange(form);
  });

  if (vehicleModelValue) {
    vehicleModelId.val(vehicleModelValue);
  }
  vehicleModelId.trigger("change");
  vehicleModelId.parsley().reset();

  if (vehicleVersionValue) {
    vehicleVersionId.val(vehicleVersionValue);
  }
  vehicleVersionId.trigger("change");
  vehicleVersionId.parsley().reset();
}

function initializeLicenseTypeSelect(form) {
  const licenseTypeSelect = form.find("#ad_car_attributes_license_type_id");
  const licensePlateInput = form.find("#ad_car_attributes_license_plate");

  licenseTypeSelect.change(function () {
    enableDependencyInput(
      licenseTypeSelect,
      licensePlateInput,
      licenseTypeSelect.val() !== "" && licenseTypeSelect.val() !== "1",
      true
    );

    licensePlateInput.attr(
      "data-parsley-remote-options",
      '{"type": "POST", "dataType": "jsonp", "data": {"ad": {"object": "car", "attribute": "license_plate", "metadata": {"license_type_id": ' +
        licenseTypeSelect.val() +
        "}}}}"
    );
  });

  licenseTypeSelect.trigger("change");
  licensePlateInput.parsley().reset();
}

function initializeMileageUnitSelect(form) {
  const mileageUnitSelect = form.find("#ad_car_attributes_mileage_unit_id");

  mileageUnitSelect.change(function () {
    mileageUnitChange(form);
  });
  mileageUnitSelect.trigger("change");
  mileageUnitSelect.parsley().reset();
}

function initializeCurrencySelect(form) {
  const currencySelect = form.find("#ad_currency");

  currencySelect.change(function () {
    const priceCrcInput = form.find("#ad_price_crc");
    const priceUsdInput = form.find("#ad_price_usd");
    const priceCrcFormGroup = priceCrcInput.closest("div.form-group");
    const priceUsdFormGroup = priceUsdInput.closest("div.form-group");

    if (currencySelect.val() === "CRC") {
      priceUsdFormGroup.hide();
      priceUsdInput.attr("required", false);
      priceCrcInput.attr("required", true);
      priceCrcFormGroup.show();
    } else {
      priceCrcFormGroup.hide();
      priceCrcInput.attr("required", false);
      priceUsdInput.attr("required", true);
      priceUsdFormGroup.show();
    }
  });
  if (currencySelect.val() === "") {
    currencySelect.val("CRC");
  }

  currencySelect.trigger("change");
  currencySelect.parsley().reset();
}

$(document).on("turbolinks:load", function () {
  if ($("div#new-ad").length > 0) {
    const newAdForm = $("form#new-ad");
    const photosContainer = $("div#photos-container");

    newAdForm.hide();
    photosContainer.hide();

    initializeForm(newAdForm);

    initializeBackButtons(newAdForm);
    initializeBackButtons(photosContainer);

    initializeNextButtons(newAdForm);
    initializeNextButtons(photosContainer);

    showSection(newAdForm.attr("id"), 1);

    initializeRetrieveExternalDataForm(newAdForm);
  } else if ($("div#edit-ad").length > 0) {
    const editAdForm = $("form#edit-ad");
    initializeForm(editAdForm);
  }
});
